define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-flag-report", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    {{#if this.canFlag}}
      <hr />
    {{/if}}
  
    <label class="radio checkbox-label">
      <input
        type="radio"
        checked={{@disableClick}}
        name="post_action_type_index"
      />
      <div class="flag-action-type-details">
        <strong>{{i18n "blizzard.report_content.title"}}</strong>
        <div class="description">{{i18n
            "blizzard.report_content.description"
          }}</div>
      </div>
    </label>
  {{/if}}
  */
  {
    "id": "s/xTpgcf",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[30,0,[\"canFlag\"]],[[[1,\"    \"],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"label\"],[14,0,\"radio checkbox-label\"],[12],[1,\"\\n    \"],[10,\"input\"],[15,\"checked\",[30,1]],[14,3,\"post_action_type_index\"],[14,4,\"radio\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"flag-action-type-details\"],[12],[1,\"\\n      \"],[10,\"strong\"],[12],[1,[28,[35,6],[\"blizzard.report_content.title\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"description\"],[12],[1,[28,[35,6],[\"blizzard.report_content.description\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@disableClick\"],false,[\"if\",\"hr\",\"label\",\"input\",\"div\",\"strong\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-flag-report.hbs",
    "isStrictMode": false
  });
  class BFlagReport extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get show() {
      // make sure DSA is enabled
      if (!this.siteSettings.blizzard_enable_dsa_prompt) {
        return false;
      }
      // if this has a title, it is most likely a TOPIC
      if (this.args.post.title) {
        return false;
      }
      return true;
    }
    get canFlag() {
      if (this.isPost && this.args.post.flagsAvailable.length > 0) {
        return true;
      }
      return false;
    }
  }
  _exports.default = BFlagReport;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BFlagReport);
});