define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-nav-breadcrumbs", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "discourse/lib/get-url", "@ember/template-factory"], function (_exports, _component, _component2, _service, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-breadcrumbs">
    <a href={{this.homeUrl}} class="b-breadcrumb b-breadcrumb-home">
      {{d-icon "blizzard-home"}}
      {{i18n "blizzard.forums"}}
    </a>
    {{#if this.category}}
      {{#if this.category.parentCategory}}
        <a
          href={{this.parentCategoryUrl}}
          class="b-breadcrumb b-breadcrumb-category"
        >
          {{d-icon "blizzard-chevron_right"}}
          {{this.category.parentCategory.name}}
        </a>
      {{/if}}
      <a href={{this.categoryUrl}} class="b-breadcrumb b-breadcrumb-category">
        {{d-icon "blizzard-chevron_right"}}
        {{this.category.name}}
      </a>
    {{/if}}
    {{#if this.topicTitle}}
      <a href={{this.topicUrl}} class="b-breadcrumb b-breadcrumb-topic">
        {{d-icon "blizzard-chevron_right"}}
        {{html-safe this.topicTitle}}
      </a>
    {{/if}}
  </div>
  */
  {
    "id": "WcHpbVUj",
    "block": "[[[10,0],[14,0,\"b-breadcrumbs\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"homeUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-home\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"blizzard-home\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"blizzard.forums\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"category\"]],[[[41,[30,0,[\"category\",\"parentCategory\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"parentCategoryUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-category\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"blizzard-chevron_right\"],null]],[1,\"\\n        \"],[1,[30,0,[\"category\",\"parentCategory\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,3],[15,6,[30,0,[\"categoryUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-category\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"blizzard-chevron_right\"],null]],[1,\"\\n      \"],[1,[30,0,[\"category\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"topicTitle\"]],[[[1,\"    \"],[10,3],[15,6,[30,0,[\"topicUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-topic\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"blizzard-chevron_right\"],null]],[1,\"\\n      \"],[1,[28,[35,5],[[30,0,[\"topicTitle\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"a\",\"d-icon\",\"i18n\",\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-nav-breadcrumbs.hbs",
    "isStrictMode": false
  });
  class BNavBreadcrumbs extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discovery", [_service.service]))();
    #discovery = (() => (dt7948.i(this, "discovery"), void 0))();
    get category() {
      // The following fetches the current category. Discourse doesn't normally
      // make this available in the header so it involves a little peeking around
      // to find the right context.
      let currentCategory = null;
      if (this.router.currentRouteName.match(/^discovery\.[a-z]*category$/i)) {
        currentCategory = this.discovery.category;
      }
      if (this.args.topic) {
        return this.args.topic.get("category");
      } else if (currentCategory && !currentCategory.get("isUncategorizedCategory")) {
        return currentCategory;
      }
    }
    get homeUrl() {
      return (0, _getUrl.default)("/");
    }
    get topicTitle() {
      return this.args.topic?.get("fancyTitle") || "";
    }
    get topicUrl() {
      return this.args.topic?.get("url");
    }
    get categoryUrl() {
      return this.category?.get("url");
    }
    get parentCategoryUrl() {
      return this.category?.parentCategory?.get("url");
    }
  }
  _exports.default = BNavBreadcrumbs;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BNavBreadcrumbs);
});