define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/topic-list-before-reply-count/b-reply-icon", ["exports", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{icon "blizzard-chat" class="blizzard-reply-icon"}}
  
  */
  {
    "id": "f5H4E79Y",
    "block": "[[[1,\"\\n  \"],[1,[28,[32,0],[\"blizzard-chat\"],[[\"class\"],[\"blizzard-reply-icon\"]]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/topic-list-before-reply-count/b-reply-icon.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "b-reply-icon"));
});