define("discourse/plugins/discourse-blizzard-plugin/discourse/components/assign-anon-report-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _anonymousReport, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AssignAnonReportButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    get shouldShowButton() {
      if (this.currentUser || this.args.post.staff || this.args.post.canFlag) {
        return false;
      }
      return true;
    }
    anonReport() {
      this.modal.show(_anonymousReport.default, {
        model: {
          flagModel: this.args.post
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "anonReport", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShowButton}}
          <DButton
            class="post-action-menu__assign-anon-report anon-report"
            ...attributes
            @action={{this.anonReport}}
            @icon="flag"
            @title="blizzard.report_content"
          />
        {{/if}}
      
    */
    {
      "id": "wQobOPHX",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShowButton\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"post-action-menu__assign-anon-report anon-report\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"anonReport\"]],\"flag\",\"blizzard.report_content\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/components/assign-anon-report-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AssignAnonReportButton;
});