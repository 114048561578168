define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-user-profile-avatar", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canChangeAvatar}}
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="user-profile-avatar selectable"
      {{action "showAvatarSelector" @user}}
      title={{i18n "user.change_avatar.label"}}
    >
      {{bound-avatar @user "huge"}}
      {{d-icon "blizzard-account"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
      />
    </div>
  {{else if this.canChangeCharacter}}
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="user-profile-avatar selectable"
      {{action "showCharacterSelector" @user}}
      title={{i18n "user.change_character.label"}}
    >
      {{bound-avatar @user "huge"}}
      {{d-icon "blizzard-account"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
      />
    </div>
  {{else}}
    <div class="user-profile-avatar">
      {{bound-avatar @user "huge"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "JEkN6j+1",
    "block": "[[[41,[30,0,[\"canChangeAvatar\"]],[[[1,\"  \"],[11,0],[24,0,\"user-profile-avatar selectable\"],[16,\"title\",[28,[37,2],[\"user.change_avatar.label\"],null]],[4,[38,3],[[30,0],\"showAvatarSelector\",[30,1]],null],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[1,[28,[35,5],[\"blizzard-account\"],null]],[1,\"\\n    \"],[8,[39,6],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,8],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"canChangeCharacter\"]],[[[1,\"  \"],[11,0],[24,0,\"user-profile-avatar selectable\"],[16,\"title\",[28,[37,2],[\"user.change_character.label\"],null]],[4,[38,3],[[30,0],\"showCharacterSelector\",[30,1]],null],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[1,[28,[35,5],[\"blizzard-account\"],null]],[1,\"\\n    \"],[8,[39,6],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,8],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"user-profile-avatar\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[8,[39,6],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,8],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@user\"],false,[\"if\",\"div\",\"i18n\",\"action\",\"bound-avatar\",\"d-icon\",\"user-avatar-flair\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-user-profile-avatar.hbs",
    "isStrictMode": false
  });
  class BUserProfileAvatar extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get canChangeAvatar() {
      return this.currentUser && this.currentUser.get("staff") && this.args.user === this.currentUser || this.siteSettings.selectable_avatars_mode !== "disabled" && this.currentUser && (this.currentUser.get("staff") || this.args.user === this.currentUser);
    }
    get canChangeCharacter() {
      return this.siteSettings.selectable_characters_enabled && this.currentUser === this.args.user;
    }
    showAvatarSelector(user) {
      this.appEvents.trigger("dom:clean", "header");
      this.appEvents.trigger("show-avatar-select", user);
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "showAvatarSelector", [_object.action]))();
    showCharacterSelector(user) {
      this.appEvents.trigger("dom:clean", "header");
      this.appEvents.trigger("show-character-select", user);
      return false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "showCharacterSelector", [_object.action]))();
  }
  _exports.default = BUserProfileAvatar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BUserProfileAvatar);
});