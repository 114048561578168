define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/notifications-tracking-icons/selection-indicator", ["exports", "@ember/component", "discourse/helpers/d-icon", "@ember/template-factory"], function (_exports, _component, _dIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SelectionIndicator extends _component.default {
    get icon() {
      return this.outletArgs.level.icon;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="selection-indicator">
        </div>
        {{icon this.icon}}
      
    */
    {
      "id": "9Zd5G8y7",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"selection-indicator\"],[12],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[32,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/notifications-tracking-icons/selection-indicator.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SelectionIndicator;
});