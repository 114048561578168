define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/after-header-panel/nav-breadcrumbs", ["exports", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-nav-breadcrumbs", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _bNavBreadcrumbs, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NavBreadcrumbs = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="b-breadcrumbs-wrap">
      <BNavBreadcrumbs @topic={{@outletArgs.topicInfo}} />
    </div>
  
  */
  {
    "id": "ZTo8YrxV",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"b-breadcrumbs-wrap\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topicInfo\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/after-header-panel/nav-breadcrumbs.js",
    "scope": () => [_bNavBreadcrumbs.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "nav-breadcrumbs:NavBreadcrumbs"));
  var _default = _exports.default = NavBreadcrumbs;
});