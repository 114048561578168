define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/login-before-modal-body/second-factor-authentication-login", ["exports", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/components/second-factor-form", "discourse/components/second-factor-input", "discourse/components/security-key-form", "discourse/helpers/with-event-value", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/lib/webauthn", "discourse/models/user", "discourse-i18n", "@ember/template-factory"], function (_exports, _tracking, _component, _helper, _modifier, _object, _dButton, _secondFactorForm, _secondFactorInput, _securityKeyForm, _withEventValue, _ajax, _utilities, _webauthn, _user, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SecondFactorAuthenticationLogin extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loggingIn", [_tracking.tracked], function () {
      return false;
    }))();
    #loggingIn = (() => (dt7948.i(this, "loggingIn"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loggedIn", [_tracking.tracked], function () {
      return false;
    }))();
    #loggedIn = (() => (dt7948.i(this, "loggedIn"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "secondFactorToken", [_tracking.tracked]))();
    #secondFactorToken = (() => (dt7948.i(this, "secondFactorToken"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "secondFactorMethod", [_tracking.tracked], function () {
      return _user.SECOND_FACTOR_METHODS.TOTP;
    }))();
    #secondFactorMethod = (() => (dt7948.i(this, "secondFactorMethod"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showSecondFactor", [_tracking.tracked], function () {
      return true;
    }))();
    #showSecondFactor = (() => (dt7948.i(this, "showSecondFactor"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "showSecurityKey", [_tracking.tracked]))();
    #showSecurityKey = (() => (dt7948.i(this, "showSecurityKey"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "backupEnabled", [_tracking.tracked]))();
    #backupEnabled = (() => (dt7948.i(this, "backupEnabled"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "totpEnabled", [_tracking.tracked]))();
    #totpEnabled = (() => (dt7948.i(this, "totpEnabled"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "otherMethodAllowed", [_tracking.tracked]))();
    #otherMethodAllowed = (() => (dt7948.i(this, "otherMethodAllowed"), void 0))();
    authenticateSecurityKey() {
      (0, _webauthn.getWebauthnCredential)(this.args.securityKeyChallenge, this.args.securityKeyAllowedCredentialIds, credentialData => {
        this.args.securityKeyCredentialChanged(credentialData);
        this.args.login();
      }, error => {
        this.args.flashChanged(error);
        this.args.flashTypeChanged("error");
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "authenticateSecurityKey", [_object.action]))();
    loginOnEnter(event) {
      if (event.key === "Enter") {
        this.trigger2FA();
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "loginOnEnter", [_object.action]))();
    flashChanged(message) {
      return this.outletArgs.flashChanged(message);
    }
    static #_12 = (() => dt7948.n(this.prototype, "flashChanged", [_object.action]))();
    flashTypeChanged(type) {
      return this.outletArgs.flashTypeChanged(type);
    }
    static #_13 = (() => dt7948.n(this.prototype, "flashTypeChanged", [_object.action]))();
    async trigger2FA() {
      try {
        this.loggingIn = true;
        const result = await (0, _ajax.ajax)("/auth/bnet/login2fa", {
          type: "POST",
          data: {
            second_factor_token: this.securityKeyCredential || this.secondFactorToken,
            second_factor_method: this.secondFactorMethod,
            timezone: moment.tz.guess()
          }
        });
        if (result && result.success) {
          this.loggingIn = false;
          this.loggedIn = true;
          this.flash = null;
          window.location.assign(result.redirect_url);
        } else if (result && result.error) {
          this.loggingIn = false;
          this.flash = null;
          if ((result.security_key_enabled || result.totp_enabled) && !this.secondFactorRequired) {
            this.otherMethodAllowed = result.multiple_second_factor_methods;
            this.backupEnabled = result.backup_enabled;
            this.totpEnabled = result.totp_enabled;
            this.showSecondFactor = result.totp_enabled;
            this.showSecurityKey = result.security_key_enabled;
            this.secondFactorMethod = result.security_key_enabled ? _user.SECOND_FACTOR_METHODS.SECURITY_KEY : _user.SECOND_FACTOR_METHODS.TOTP;
          }
          this.flashChanged(result.error);
          this.flashTypeChanged("error");
        }
      } catch (e) {
        // Failed to login
        if (e.jqXHR && e.jqXHR.status === 429) {
          this.flash = (0, _discourseI18n.i18n)("login.rate_limit");
          this.flashType = "error";
        } else if (e.jqXHR && e.jqXHR.status === 503 && e.jqXHR.responseJSON.error_type === "read_only") {
          this.flashChanged((0, _discourseI18n.i18n)("read_only_mode.login_disabled"));
          this.flashTypeChanged("error");
        } else if (!(0, _utilities.areCookiesEnabled)()) {
          this.flashChanged((0, _discourseI18n.i18n)("login.cookies_error"));
          this.flashTypeChanged("error");
        } else {
          this.flashChanged((0, _discourseI18n.i18n)("login.error"));
          this.flashTypeChanged("error");
        }
        this.loggingIn = false;
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "trigger2FA", [_object.action]))();
    get loginButtonLabel() {
      return this.loggingIn ? "login.logging_in" : "login.title";
    }
    get loginDisabled() {
      return this.loggingIn || this.loggedIn;
    }
    static #_15 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showSecondFactor}}
          <div class="blz-second-factor-form">
            <SecondFactorForm
              @secondFactorMethod={{this.secondFactorMethod}}
              @secondFactorToken={{this.secondFactorToken}}
              @backupEnabled={{this.backupEnabled}}
              @totpEnabled={{this.totpEnabled}}
              @isLogin={{true}}
            >
              {{#if this.showSecurityKey}}
                <SecurityKeyForm
                  @setShowSecurityKey={{this.showSecurityKey}}
                  @setShowSecondFactor={{this.showSecondFactor}}
                  @setSecondFactorMethod={{this.secondFactorMethod}}
                  @backupEnabled={{this.backupEnabled}}
                  @totpEnabled={{this.totpEnabled}}
                  @otherMethodAllowed={{this.otherMethodAllowed}}
                  @action={{this.authenticateSecurityKey}}
                />
              {{else}}
                <SecondFactorInput
                  {{on "keydown" this.loginOnEnter}}
                  {{on "input" (withEventValue (fn (mut this.secondFactorToken)))}}
                  @secondFactorMethod={{this.secondFactorMethod}}
                  value={{this.secondFactorToken}}
                  id="login-second-factor"
                />
              {{/if}}
            </SecondFactorForm>
            <DButton
              @action={{this.trigger2FA}}
              @disabled={{this.loginDisabled}}
              @isLoading={{this.loggingIn}}
              @label={{this.loginButtonLabel}}
              id="login-button"
              form="login-form"
              class="btn-large btn-primary login-page-cta__login"
              tabindex={{unless this.showSecondFactor "2"}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "gyHm7a7B",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showSecondFactor\"]],[[[1,\"      \"],[10,0],[14,0,\"blz-second-factor-form\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@secondFactorMethod\",\"@secondFactorToken\",\"@backupEnabled\",\"@totpEnabled\",\"@isLogin\"],[[30,0,[\"secondFactorMethod\"]],[30,0,[\"secondFactorToken\"]],[30,0,[\"backupEnabled\"]],[30,0,[\"totpEnabled\"]],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showSecurityKey\"]],[[[1,\"            \"],[8,[32,1],null,[[\"@setShowSecurityKey\",\"@setShowSecondFactor\",\"@setSecondFactorMethod\",\"@backupEnabled\",\"@totpEnabled\",\"@otherMethodAllowed\",\"@action\"],[[30,0,[\"showSecurityKey\"]],[30,0,[\"showSecondFactor\"]],[30,0,[\"secondFactorMethod\"]],[30,0,[\"backupEnabled\"]],[30,0,[\"totpEnabled\"]],[30,0,[\"otherMethodAllowed\"]],[30,0,[\"authenticateSecurityKey\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[32,2],[[16,2,[30,0,[\"secondFactorToken\"]]],[24,1,\"login-second-factor\"],[4,[32,3],[\"keydown\",[30,0,[\"loginOnEnter\"]]],null],[4,[32,3],[\"input\",[28,[32,4],[[28,[32,5],[[28,[31,1],[[30,0,[\"secondFactorToken\"]]],null]],null]],null]],null]],[[\"@secondFactorMethod\"],[[30,0,[\"secondFactorMethod\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n        \"],[8,[32,6],[[24,1,\"login-button\"],[24,\"form\",\"login-form\"],[24,0,\"btn-large btn-primary login-page-cta__login\"],[16,\"tabindex\",[52,[51,[30,0,[\"showSecondFactor\"]]],\"2\"]]],[[\"@action\",\"@disabled\",\"@isLoading\",\"@label\"],[[30,0,[\"trigger2FA\"]],[30,0,[\"loginDisabled\"]],[30,0,[\"loggingIn\"]],[30,0,[\"loginButtonLabel\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\",\"mut\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/login-before-modal-body/second-factor-authentication-login.js",
      "scope": () => [_secondFactorForm.default, _securityKeyForm.default, _secondFactorInput.default, _modifier.on, _withEventValue.default, _helper.fn, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SecondFactorAuthenticationLogin;
});