define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/b-category-icon", ["exports", "discourse/lib/get-url"], function (_exports, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = categoryIconPath;
  function categoryIconPath(category) {
    if (category) {
      // Get icon from uploaded logo or fallback to bolt icon
      return category.get("uploaded_logo")?.url || (0, _getUrl.getURLWithCDN)(`/plugins/discourse-blizzard-plugin/images/icons/bolt.png?1530125097`);
    }
  }
});