define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/post-list-additional-member-info/post-list-additional-member-info", ["exports", "@ember/component", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostListAdditionalMemberInfo extends _component.default {
    get postCount() {
      return this.outletArgs.post.user.post_count;
    }
    get userTitle() {
      return this.outletArgs.post.user.title;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.userTitle}}
          <span class="user-title">{{this.userTitle}}</span>
        {{/if}}
        {{#if this.postCount}}
          <div class="post-count">
            {{i18n "user_posts_count" count=this.postCount}}
          </div>
        {{/if}}
      
    */
    {
      "id": "xg+GxSUP",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"userTitle\"]],[[[1,\"      \"],[10,1],[14,0,\"user-title\"],[12],[1,[30,0,[\"userTitle\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"postCount\"]],[[[1,\"      \"],[10,0],[14,0,\"post-count\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user_posts_count\"],[[\"count\"],[[30,0,[\"postCount\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/post-list-additional-member-info/post-list-additional-member-info.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostListAdditionalMemberInfo;
});