define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href={{this.href}} class="b-category d-link {{this.hasDescription}}">
    <div class="category-icon">
      <img alt="" src={{b-category-icon @category}} />
    </div>
  
    <div class="category-information">
      <h4 class="heading">{{@category.name}}</h4>
      {{#if @category.description_excerpt}}
        <h5 class="category-description">{{@category.description_excerpt}}</h5>
      {{/if}}
  
      {{#if @category.subcategories}}
        <div class="subcategories">
          {{#each @category.subcategories as |c|}}
            <BCategorySmall @category={{c}} />
          {{/each}}
        </div>
      {{/if}}
    </div>
  </a>
  */
  {
    "id": "/UhyptzA",
    "block": "[[[10,3],[15,6,[30,0,[\"href\"]]],[15,0,[29,[\"b-category d-link \",[30,0,[\"hasDescription\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"category-icon\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[28,[37,3],[[30,1]],null]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"category-information\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"heading\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"description_excerpt\"]],[[[1,\"      \"],[10,\"h5\"],[14,0,\"category-description\"],[12],[1,[30,1,[\"description_excerpt\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"subcategories\"]],[[[1,\"      \"],[10,0],[14,0,\"subcategories\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,1,[\"subcategories\"]]],null]],null],null,[[[1,\"          \"],[8,[39,9],null,[[\"@category\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@category\",\"c\"],false,[\"a\",\"div\",\"img\",\"b-category-icon\",\"h4\",\"if\",\"h5\",\"each\",\"-track-array\",\"b-category-small\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category.hbs",
    "isStrictMode": false
  });
  class BCategory extends _component2.default {
    get hasDescription() {
      return this.args.category.description_excerpt && this.args.category.description_excerpt.length ? "has-description" : "no-description";
    }
    get href() {
      if (this.args.category) {
        return this.args.category.url;
      }
      return null;
    }
  }
  _exports.default = BCategory;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BCategory);
});