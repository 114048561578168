define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/topic-category-wrapper/topic-category", ["exports", "@ember/component", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-small", "@ember/template-factory"], function (_exports, _component, _bCategorySmall, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoriesOnlyWrapper extends _component.default {
    get topicCategory() {
      return this.outletArgs.topic.category;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.topicCategory}}
          <BCategorySmall @category={{this.topicCategory}} />
        {{/if}}
      
    */
    {
      "id": "VgpHVjDd",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"topicCategory\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@category\"],[[30,0,[\"topicCategory\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/topic-category-wrapper/topic-category.js",
      "scope": () => [_bCategorySmall.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoriesOnlyWrapper;
});