define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/category-name-fields-details/category-name-fields", ["exports", "@ember/component", "discourse-i18n", "discourse/plugins/discourse-blizzard-plugin/discourse/components/category-field-name", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _categoryFieldName, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryNameFields extends _component.default {
    get category() {
      return this.outletArgs.category;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="category-name-fields">
          <section class="field">
            <section class="field-item">
              <label>{{i18n "category.name"}}</label>
              <CategoryFieldName
                @value={{this.category.name}}
                @placeholderKey="category.name_placeholder"
                @maxlength="50"
                titleKey="category.name_placeholder"
              />
            </section>
            <section class="field-item">
              <label>{{i18n "category.slug"}}</label>
              <CategoryFieldName
                @value={{this.category.slug}}
                @placeholderKey="category.slug_placeholder"
                @maxlength="255"
                titleKey="category.slug_placeholder"
              />
            </section>
          </section>
        </div>
      
    */
    {
      "id": "M08fvwpJ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"category-name-fields\"],[12],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,0],[\"category.name\"],null]],[13],[1,\"\\n          \"],[8,[32,1],[[24,\"titleKey\",\"category.name_placeholder\"]],[[\"@value\",\"@placeholderKey\",\"@maxlength\"],[[30,0,[\"category\",\"name\"]],\"category.name_placeholder\",\"50\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,0],[\"category.slug\"],null]],[13],[1,\"\\n          \"],[8,[32,1],[[24,\"titleKey\",\"category.slug_placeholder\"]],[[\"@value\",\"@placeholderKey\",\"@maxlength\"],[[30,0,[\"category\",\"slug\"]],\"category.slug_placeholder\",\"255\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/category-name-fields-details/category-name-fields.js",
      "scope": () => [_discourseI18n.i18n, _categoryFieldName.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryNameFields;
});