define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-main-link-bottom-row/main-link-bottom-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "x18GJrR7",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-main-link-bottom-row/main-link-bottom-row.hbs",
    "isStrictMode": false
  });
});