define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-topic-stats/topic-stats", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Parent element for category name and the age of the post }}
  <div class="b-topic-line category-and-age">
    {{#if this.topic.category}}
      <BCategorySmall @category={{this.topic.category}} />
    {{/if}}
  
    <a
      href={{this.topic.lastPostUrl}}
      class="b-age"
      title={{this.topic.bumpedAtTitle}}
    >
      {{format-date this.topic.bumpedAt format="tiny" noTitle="true"}}
    </a>
  </div>
  */
  {
    "id": "fgBMbqO0",
    "block": "[[[10,0],[14,0,\"b-topic-line category-and-age\"],[12],[1,\"\\n\"],[41,[30,0,[\"topic\",\"category\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@category\"],[[30,0,[\"topic\",\"category\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"topic\",\"lastPostUrl\"]]],[14,0,\"b-age\"],[15,\"title\",[30,0,[\"topic\",\"bumpedAtTitle\"]]],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,0,[\"topic\",\"bumpedAt\"]]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"if\",\"b-category-small\",\"a\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-topic-stats/topic-stats.hbs",
    "isStrictMode": false
  });
});