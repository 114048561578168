define("discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/character-selector", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _ajax, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "user.change_character.title"}}
    @closeModal={{@closeModal}}
    class="character-selector character-selector-modal"
  >
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      <div class="character-filter">
        {{d-icon "magnifying-glass"}}
        <TextField
          @autofocus="autofocus"
          @placeholderKey="user.change_character.filter.placeholder"
          @value={{this.filter}}
        />
      </div>
  
      <div class="selectable-characters">
        {{#each this.filteredCharacters as |character|}}
          {{! template-lint-disable no-invalid-interactive }}
          <div
            class="selectable-character"
            {{action "selectCharacter" character}}
          >
            {{bound-avatar-template character.avatar "huge"}}
            <CharacterDetails
              @character={{character}}
              @showName="true"
              @guildBelow="true"
            />
          </div>
        {{else}}
          <div class="no-results">
            {{i18n "user.change_character.no_results"}}
          </div>
        {{/each}}
      </div>
    </ConditionalLoadingSpinner>
  </DModal>
  */
  {
    "id": "Z6IKONTj",
    "block": "[[[8,[39,0],[[24,0,\"character-selector character-selector-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"user.change_character.title\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"character-filter\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"magnifying-glass\"],null]],[1,\"\\n      \"],[8,[39,5],null,[[\"@autofocus\",\"@placeholderKey\",\"@value\"],[\"autofocus\",\"user.change_character.filter.placeholder\",[30,0,[\"filter\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"selectable-characters\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"filteredCharacters\"]]],null]],null],null,[[[1,\"        \"],[11,0],[24,0,\"selectable-character\"],[4,[38,8],[[30,0],\"selectCharacter\",[30,2]],null],[12],[1,\"\\n          \"],[1,[28,[35,9],[[30,2,[\"avatar\"]],\"huge\"],null]],[1,\"\\n          \"],[8,[39,10],null,[[\"@character\",\"@showName\",\"@guildBelow\"],[[30,2],\"true\",\"true\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],[[[1,\"        \"],[10,0],[14,0,\"no-results\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"user.change_character.no_results\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"character\"],false,[\"d-modal\",\"i18n\",\"conditional-loading-spinner\",\"div\",\"d-icon\",\"text-field\",\"each\",\"-track-array\",\"action\",\"bound-avatar-template\",\"character-details\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/character-selector.hbs",
    "isStrictMode": false
  });
  class CharacterSelector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked]))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    filter = null;
    characters = null;
    user;
    init() {
      this.user = this.model.user;
      this.loading = true;
      this.filter = null;
      this.characters = null;
      (0, _ajax.ajax)(`/u/${this.user.get("username_lower")}/characters`).then(result => {
        this.characters = result.characters;
        this.loading = false;
      });
      super.init(...arguments);
    }
    filteredCharacters(filter, characters) {
      if (filter && /\S/.test(filter)) {
        filter = new RegExp(filter.trim(), "i");
        return (characters || []).filter(c => {
          return filter.test(c.name) || filter.test(c.class) || filter.test(c.race) || filter.test(c.realm);
        });
      } else {
        return characters;
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "filteredCharacters", [(0, _decorators.default)("filter", "characters")]))();
    selectCharacter(character) {
      let user = this.user;
      (0, _ajax.ajax)(`/u/${user.get("username_lower")}/characters/select`, {
        data: {
          name: character.name
        },
        method: "PUT"
      }).then(() => {
        window.location.reload();
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "selectCharacter", [_object.action]))();
  }
  _exports.default = CharacterSelector;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CharacterSelector);
});