define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/category-custom-settings/blizzard-category-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="blizzard-category-settings">
    {{#if this.siteSettings.blizzard_group_categories}}
      <section class="field">
        <label>
          {{i18n "admin.blizzard.category_group"}}
        </label>
        <div class="controls">
          {{text-field value=this.category.custom_fields.group}}
        </div>
      </section>
    {{/if}}
  
    {{#if this.siteSettings.blizzard_realms_enabled}}
      <section class="field">
        {{category-realm-checkbox category=this.category}}
      </section>
    {{/if}}
  </div>
  */
  {
    "id": "O/AqqY1n",
    "block": "[[[10,0],[14,0,\"blizzard-category-settings\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_group_categories\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"admin.blizzard.category_group\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"group\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_realms_enabled\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n      \"],[1,[28,[35,6],null,[[\"category\"],[[30,0,[\"category\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"if\",\"section\",\"label\",\"i18n\",\"text-field\",\"category-realm-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/category-custom-settings/blizzard-category-settings.hbs",
    "isStrictMode": false
  });
});