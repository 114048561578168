define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-mobile-subcategories", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-mobile-subcategories">
    <div class="parent-category">
      <img alt="" src={{b-category-icon @category}} />
      <a
        class="parent-category-title"
        href={{@category.url}}
      >{{@category.name}}</a>
    </div>
    <div class="subcategories">
      {{#each @category.subcategories as |c|}}
        <div class="b-subcategory">
          <img alt="" src={{b-category-icon c}} />
          <a class="category-title" href={{c.url}}>{{c.name}}</a>
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "4hIvdp/e",
    "block": "[[[10,0],[14,0,\"b-mobile-subcategories\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"parent-category\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[28,[37,2],[[30,1]],null]],[12],[13],[1,\"\\n    \"],[10,3],[14,0,\"parent-category-title\"],[15,6,[30,1,[\"url\"]]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"subcategories\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"subcategories\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"b-subcategory\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[28,[37,2],[[30,2]],null]],[12],[13],[1,\"\\n        \"],[10,3],[14,0,\"category-title\"],[15,6,[30,2,[\"url\"]]],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@category\",\"c\"],false,[\"div\",\"img\",\"b-category-icon\",\"a\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-mobile-subcategories.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "b-mobile-subcategories"));
});