define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/exception", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="error-message exception">
    <div class="error-type">{{this.thrown.status}}</div>
    <div class="error-issue">{{this.reason}}</div>
    <div class="error-button-row">
      <LinkTo @route="discovery.categories" class="btn btn-primary btn-icon-text">
        <span class="button-content">{{i18n "blizzard.return_to_forum"}}</span>
      </LinkTo>
    </div>
  </div>
  */
  {
    "id": "5HEqZ0wL",
    "block": "[[[10,0],[14,0,\"error-message exception\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"error-type\"],[12],[1,[30,0,[\"thrown\",\"status\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"error-issue\"],[12],[1,[30,0,[\"reason\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"error-button-row\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn btn-primary btn-icon-text\"]],[[\"@route\"],[\"discovery.categories\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"button-content\"],[12],[1,[28,[35,3],[\"blizzard.return_to_forum\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"link-to\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/exception.hbs",
    "isStrictMode": false
  });
});