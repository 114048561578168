define("discourse/plugins/discourse-blizzard-plugin/discourse/components/category-field-name", ["exports", "discourse/components/text-field", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _textField, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Same as text-field, but adding the placeholder text as title
  **/
  class CategoryFieldName extends _textField.default {
    title(titleKey) {
      return titleKey ? (0, _discourseI18n.i18n)(titleKey) : "";
    }
    static #_ = (() => dt7948.n(this.prototype, "title", [(0, _decorators.default)("titleKey")]))();
  }
  _exports.default = CategoryFieldName;
});