define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-preferences-security/second-factor-manager", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SecondFactorManager extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    async manage2FA() {
      try {
        // Confirmation is removed since they have external login
        await this.router.transitionTo("preferences.second-factor");
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "manage2FA", [_object.action]))();
    get allowedToCreate2fa() {
      return this.currentUser && this.currentUser.staff;
    }
    static #_5 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.allowedToCreate2fa}}
          <div
            class="control-group pref-second-factor"
            data-setting-name="user-second-factor"
          >
            <label class="control-label">{{i18n "user.second_factor.title"}}</label>
            <div class="instructions">
              {{i18n "user.second_factor.short_description"}}
            </div>
    
            <div class="controls pref-second-factor">
              <DButton
                @action={{this.manage2FA}}
                @icon="lock"
                @label="user.second_factor.enable"
                class="btn-default btn-second-factor"
              />
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "owjvBOXG",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"allowedToCreate2fa\"]],[[[1,\"      \"],[10,0],[14,0,\"control-group pref-second-factor\"],[14,\"data-setting-name\",\"user-second-factor\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[32,0],[\"user.second_factor.title\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"user.second_factor.short_description\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls pref-second-factor\"],[12],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-default btn-second-factor\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"manage2FA\"]],\"lock\",\"user.second_factor.enable\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-preferences-security/second-factor-manager.js",
      "scope": () => [_discourseI18n.i18n, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SecondFactorManager;
});