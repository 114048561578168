define("discourse/plugins/discourse-blizzard-plugin/discourse/components/user-post-count", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div role="heading" class="user-post-count">
    {{#if @userPostCount}}
      {{i18n "user_posts_count" count=@userPostCount}}
    {{/if}}
  </div>
  */
  {
    "id": "ZCkuMyI1",
    "block": "[[[10,0],[14,\"role\",\"heading\"],[14,0,\"user-post-count\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[1,[28,[35,2],[\"user_posts_count\"],[[\"count\"],[[30,1]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@userPostCount\"],false,[\"div\",\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/user-post-count.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "user-post-count"));
});