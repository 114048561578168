define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-account-link", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-account-link">
    <div class="control-group pref-email">
      <label class="control-label">{{i18n "blizzard_email.title"}}</label>
      <div class="controls">
        <a
          href="{{this.siteSettings.battle_net_base_url}}/account/"
          target="_blank"
          class="battle-net-link"
          rel="noopener noreferrer"
        >
          {{i18n "blizzard_email.link"}}
          {{d-icon "up-right-from-square"}}
        </a>
      </div>
      <div class="instructions">
        {{i18n "blizzard_email.instructions"}}
      </div>
    </div>
  </div>
  */
  {
    "id": "FKc0EAfz",
    "block": "[[[10,0],[14,0,\"b-account-link\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group pref-email\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"blizzard_email.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"battle_net_base_url\"]],\"/account/\"]]],[14,\"target\",\"_blank\"],[14,0,\"battle-net-link\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"blizzard_email.link\"],null]],[1,\"\\n        \"],[1,[28,[35,4],[\"up-right-from-square\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"blizzard_email.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"a\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-account-link.hbs",
    "isStrictMode": false
  });
  class BAccountLink extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
  }
  _exports.default = BAccountLink;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BAccountLink);
});