define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-refresh-profile-button", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{this.updateProfile}}
    @icon={{this.buttonIcon}}
    @label="admin.blizzard.refresh_profile"
    @disabled={{this.loading}}
    class="btn-danger update-profile-button"
  />
  */
  {
    "id": "O21VcuBd",
    "block": "[[[8,[39,0],[[24,0,\"btn-danger update-profile-button\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"updateProfile\"]],[30,0,[\"buttonIcon\"]],\"admin.blizzard.refresh_profile\",[30,0,[\"loading\"]]]],null]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-refresh-profile-button.hbs",
    "isStrictMode": false
  });
  class BRefreshProfileButton extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "done", [_tracking.tracked], function () {
      return false;
    }))();
    #done = (() => (dt7948.i(this, "done"), void 0))();
    get buttonIcon() {
      if (this.loading) {
        return "spinner";
      } else if (this.done) {
        return "blizzard-check";
      }
      return "arrows-rotate";
    }
    updateProfile() {
      const user = this.args.model;
      const path = `/blizzard/refresh_profile/${user.id}`;
      this.loading = true;
      return (0, _ajax.ajax)(path, {
        type: "POST"
      }).then(() => {
        this.loading = false;
        this.done = true;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
        this.done = false;
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateProfile", [_object.action]))();
  }
  _exports.default = BRefreshProfileButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BRefreshProfileButton);
});