define("discourse/plugins/discourse-blizzard-plugin/lib/blizzard-categories", ["exports", "discourse/models/category", "discourse-i18n"], function (_exports, _category, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addMetaData = addMetaData;
  _exports.getMetaData = getMetaData;
  _exports.groupCategories = groupCategories;
  let _metaData = {};
  let _defaultMetaData = {};
  let _groups = {};
  let _groupedCategories;
  function getMetaData(id, field) {
    let metadata = _metaData[id];
    if (metadata && metadata[field]) {
      return metadata[field];
    }
    return _defaultMetaData[field];
  }
  function addMetaData(id, data) {
    _metaData[id] = data;

    // preventing core warning 'Category.findById called with a string ID'
    const parsed_id = parseInt(id, 10);
    let category = _category.default.findById(parsed_id);
    if (category) {
      category.set("category_metadata", data);
    }
    if (data.group) {
      let group = _groups[data.group];
      if (!group) {
        group = {
          id: data.group,
          categories: []
        };
        _groups[data.group] = group;
      }
      group.categories.push(id);
    }
  }

  // translate but return null as default
  const t = key => (0, _discourseI18n.i18n)(key, {
    defaultValue: ""
  });
  function groupCategories(categories, _ref) {
    let {
      site,
      siteSettings
    } = _ref;
    if (_groupedCategories) {
      return _groupedCategories;
    }
    let franchise = site.get("blizzard_franchise");
    let byId = {};
    categories.forEach(c => byId[c.get("id")] = c);
    let orderedGroups = [];
    _groupedCategories = [];
    Object.keys(_groups).forEach(name => {
      let group = _groups[name];
      let lowest = 999999;
      group.categories.forEach(id => {
        let category = byId[id];
        if (category) {
          let position = category.get("position");
          if (position != null && position < lowest) {
            lowest = position;
          }
        }
      });
      group.position = lowest;
      orderedGroups.push(group);
    });
    orderedGroups = orderedGroups.sort((a, b) => a.position - b.position);
    orderedGroups.forEach(og => {
      let groupCats = og.categories.map(c => {
        let result = byId[c];
        delete byId[c];
        return result;
      }).filter(Boolean);
      if (groupCats.length) {
        _groupedCategories.push({
          // If a translated key exists for this franchise/group name, use it. Otherwise use the
          // key itself. Going forward we will stop using the keys and simply use the strings
          name: t(`${franchise}.category_groups.${og.id}`) || og.id,
          categories: groupCats
        });
      }
    });
    let missed = Object.keys(byId);
    if (missed.length) {
      _groupedCategories.push({
        // It doesn't make sense to have `uncategorized` by franchise, so we've moved the key.
        // This allows backwards compatibility with the old key until our translations are in sync.
        name: t(`category_groups.uncategorized`) || t("overwatch.category_groups.uncategorized"),
        categories: missed.map(c => byId[c])
      });
    }

    // sort the categories if positions are fixed
    if (siteSettings.fixed_category_positions) {
      _groupedCategories.forEach(group => {
        group.categories = group.categories.sort((a, b) => a.get("position") - b.get("position"));
      });
    }
    return _groupedCategories;
  }
});