define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-card-buttons", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _component2, _object, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="usercard-additional-controls">
    <DButton
      class="btn-primary user-card-button"
      @action={{this.showUserActivity}}
      @icon="blizzard-chat"
      @label="user.view_activity"
    />
  
    {{#if this.user.custom_fields.profile_url}}
      <DButton
        class="btn-primary user-card-button"
        @action={{this.showProfile}}
        @icon="user"
        @label="user.view_profile"
      />
    {{/if}}
  </div>
  */
  {
    "id": "wfY4zpwm",
    "block": "[[[10,0],[14,0,\"usercard-additional-controls\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn-primary user-card-button\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"showUserActivity\"]],\"blizzard-chat\",\"user.view_activity\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"user\",\"custom_fields\",\"profile_url\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn-primary user-card-button\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"showProfile\"]],\"user\",\"user.view_profile\"]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-card-buttons.hbs",
    "isStrictMode": false
  });
  class BCardButtons extends _component2.default {
    showUserActivity() {
      let user = this.args.user;
      this.args.close();
      _url.default.routeTo((0, _url.userPath)(`${user.username}/activity`));
    }
    static #_ = (() => dt7948.n(this.prototype, "showUserActivity", [_object.action]))();
    showProfile() {
      let user = this.args.user;
      this.args.close();
      let profileUrl = user?.custom_fields?.profile_url;
      window.open(profileUrl, "_blank");
    }
    static #_2 = (() => dt7948.n(this.prototype, "showProfile", [_object.action]))();
  }
  _exports.default = BCardButtons;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BCardButtons);
});