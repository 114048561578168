define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-subcategory", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href={{@category.url}} class="b-subcategory">
    <div class="category-icon">
      <img alt="" src={{b-category-icon @category}} />
    </div>
    <div class="category-name">
      {{@category.name}}
    </div>
  </a>
  */
  {
    "id": "F/1ih5hl",
    "block": "[[[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"b-subcategory\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"category-icon\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[28,[37,3],[[30,1]],null]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"category-name\"],[12],[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@category\"],false,[\"a\",\"div\",\"img\",\"b-category-icon\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-subcategory.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "b-subcategory"));
});