define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-after-username/post-count", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.post_count}}
    <div class="usercard-post-count">
      {{i18n "user_posts_count" count=this.user.post_count}}
    </div>
  {{/if}}
  */
  {
    "id": "9evdgxkj",
    "block": "[[[41,[30,0,[\"user\",\"post_count\"]],[[[1,\"  \"],[10,0],[14,0,\"usercard-post-count\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"user_posts_count\"],[[\"count\"],[[30,0,[\"user\",\"post_count\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-after-username/post-count.hbs",
    "isStrictMode": false
  });
});