define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/composer-open/blizzard-staff-post", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.staff}}
    <div class="compose-admin-message">
      {{i18n "admin.blizzard.compose_message"}}
    </div>
  {{/if}}
  */
  {
    "id": "0ypEHh4M",
    "block": "[[[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"  \"],[10,0],[14,0,\"compose-admin-message\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.blizzard.compose_message\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/composer-open/blizzard-staff-post.hbs",
    "isStrictMode": false
  });
});