define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-summary-topic-wrapper/user-summary", ["exports", "@ember/component", "discourse/components/topic-list/latest-topic-list-item", "@ember/template-factory"], function (_exports, _component, _latestTopicListItem, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserSummary extends _component.default {
    get topic() {
      return this.outletArgs.topic;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <LatestTopicListItem @topic={{this.topic}} />
      
    */
    {
      "id": "fzXS/1aL",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,0,[\"topic\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-summary-topic-wrapper/user-summary.js",
      "scope": () => [_latestTopicListItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserSummary;
});