define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-profile-avatar-wrapper/user-profile-avatar-wrapper", ["exports", "@ember/component", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-user-profile-avatar", "@ember/template-factory"], function (_exports, _component, _bUserProfileAvatar, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserProfileAvatarWrapper extends _component.default {
    get user() {
      return this.outletArgs.user;
    }
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <BUserProfileAvatar @user={{this.user}} />
      
    */
    {
      "id": "M9kwBaAF",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@user\"],[[30,0,[\"user\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/user-profile-avatar-wrapper/user-profile-avatar-wrapper.js",
      "scope": () => [_bUserProfileAvatar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserProfileAvatarWrapper;
});