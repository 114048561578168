define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-main-link-top-row/main-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Parent element for topic icons, topic name, and reply count }}
  <div class="b-topic-line title-and-reply-count">
    <div class="b-topic-link main-link">
      {{raw "topic-status" topic=this.topic}}
      {{topic-link this.topic}}
    </div>
  
    {{raw "list/posts-count-column" topic=this.topic tagName="div"}}
  </div>
  */
  {
    "id": "fsrpoo5s",
    "block": "[[[10,0],[14,0,\"b-topic-line title-and-reply-count\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"b-topic-link main-link\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"topic-status\"],[[\"topic\"],[[30,0,[\"topic\"]]]]]],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"topic\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],[\"list/posts-count-column\"],[[\"topic\",\"tagName\"],[[30,0,[\"topic\"]],\"div\"]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"raw\",\"topic-link\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/latest-topic-list-item-main-link-top-row/main-link.hbs",
    "isStrictMode": false
  });
});