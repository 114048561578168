define("discourse/plugins/discourse-blizzard-plugin/discourse/connectors/categories-only-wrapper/categories-only-wrapper", ["exports", "@ember/component", "@ember/service", "discourse/plugins/discourse-blizzard-plugin/lib/blizzard-categories", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-categories-columned", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-groups", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-realms-list", "@ember/template-factory"], function (_exports, _component, _service, _blizzardCategories, _bCategoriesColumned, _bCategoryGroups, _bRealmsList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoriesOnlyWrapper extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get categories() {
      return this.outletArgs.categories;
    }
    get categoryGroups() {
      return (0, _blizzardCategories.groupCategories)(this.categories, {
        site: this.site,
        siteSettings: this.siteSettings
      });
    }
    static #_3 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.categories}}
          <div class="category-list">
            {{#if this.siteSettings.blizzard_group_categories}}
              <BCategoryGroups
                @groupsToExtract={{this.categoryGroups}}
                @filter="yours"
              />
            {{else}}
              <BCategoriesColumned
                @categories={{this.categories}}
                @filter="yours"
              />
            {{/if}}
          </div>
        {{/if}}
    
        {{#if this.siteSettings.blizzard_realms_enabled}}
          <BRealmsList @categories={{this.categories}} @filter="yours" />
          <BRealmsList @categories={{this.categories}} @filter="classic" />
          <BRealmsList @categories={{this.categories}} @filter="all" />
        {{/if}}
      
    */
    {
      "id": "uWDWT4Qd",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"categories\"]],[[[1,\"      \"],[10,0],[14,0,\"category-list\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_group_categories\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@groupsToExtract\",\"@filter\"],[[30,0,[\"categoryGroups\"]],\"yours\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],null,[[\"@categories\",\"@filter\"],[[30,0,[\"categories\"]],\"yours\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_realms_enabled\"]],[[[1,\"      \"],[8,[32,2],null,[[\"@categories\",\"@filter\"],[[30,0,[\"categories\"]],\"yours\"]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@categories\",\"@filter\"],[[30,0,[\"categories\"]],\"classic\"]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@categories\",\"@filter\"],[[30,0,[\"categories\"]],\"all\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/connectors/categories-only-wrapper/categories-only-wrapper.js",
      "scope": () => [_bCategoryGroups.default, _bCategoriesColumned.default, _bRealmsList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoriesOnlyWrapper;
});