define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-groups", ["exports", "@ember/component", "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category", "@ember/template-factory"], function (_exports, _component, _bCategory, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BCategoryGroups extends _component.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#each @groupsToExtract as |cg|}}
          <div class="category-group-name">{{cg.name}}</div>
          <div class="b-category-container">
            {{#each cg.categories as |c|}}
              <BCategory @category={{c}} />
            {{/each}}
          </div>
        {{/each}}
      
    */
    {
      "id": "f2QmCvFp",
      "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"category-group-name\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"b-category-container\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2,[\"categories\"]]],null]],null],null,[[[1,\"          \"],[8,[32,0],null,[[\"@category\"],[[30,3]]],null],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[\"@groupsToExtract\",\"cg\",\"c\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-groups.js",
      "scope": () => [_bCategory.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BCategoryGroups;
});