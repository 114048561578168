define("discourse/plugins/discourse-blizzard-plugin/api-initializers/customize-topic-list", ["exports", "discourse/lib/api", "discourse/lib/utilities", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _api, _utilities, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicAuthorHeader = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <th>Author</th>
  */
  {
    "id": "h4/gXTNr",
    "block": "[[[10,\"th\"],[12],[1,\"Author\"],[13]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/api-initializers/customize-topic-list.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "customize-topic-list:TopicAuthorHeader"));
  const TopicAuthorItem = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td>
  
      <div class="creator">
        <a
          href={{@topic.creator.path}}
          data-user-card={{@topic.creator.username}}
        >
          {{#if @topic.creator.name}}
            {{@topic.creator.name}}
          {{else}}
            {{formatUsername @topic.creator.username}}
          {{/if}}
        </a>
      </div>
    </td>
  
  */
  {
    "id": "72OnZusQ",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n\\n    \"],[10,0],[14,0,\"creator\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"creator\",\"path\"]]],[15,\"data-user-card\",[30,1,[\"creator\",\"username\"]]],[12],[1,\"\\n\"],[41,[30,1,[\"creator\",\"name\"]],[[[1,\"          \"],[1,[30,1,[\"creator\",\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,0],[[30,1,[\"creator\",\"username\"]]],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-blizzard-plugin/api-initializers/customize-topic-list.js",
    "scope": () => [_utilities.formatUsername],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "customize-topic-list:TopicAuthorItem"));
  var _default = _exports.default = (0, _api.apiInitializer)("1.34", api => {
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      columns.add("author", {
        header: TopicAuthorHeader,
        item: TopicAuthorItem
      });
      columns.reposition("author", {
        before: "replies"
      });
      return columns;
    });
    api.registerValueTransformer("topic-list-item-class", _ref2 => {
      let {
        value: classNames,
        context
      } = _ref2;
      const topic = context.topic;
      //topics should remain pinned regardless of user sessions/user "unpinned" state
      if (topic.pinned || topic.unpinned) {
        classNames.push("blizzard-pinned");
      }
      return classNames;
    });
    api.registerBehaviorTransformer("topic-list-item-click", _ref3 => {
      let {
        context
      } = _ref3;
      // If the user clicks on the topic list item rows, navigate to the topic
      const eventTarget = context.event.target;
      const topic = context.topic;
      if (eventTarget.tagName !== "INPUT" && eventTarget.className !== "star" && eventTarget.tagName !== "A" && !eventTarget.classList.contains("first-tracked-post")) {
        return context.navigateToTopic(topic);
      }
    });
  });
});