define("discourse/plugins/discourse-blizzard-plugin/initializers/customize-widgets", ["exports", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/get-url", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-i18n"], function (_exports, _tracking, _object, _computed, _ajax, _getUrl, _pluginApi, _utilities, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "customize-widgets",
    after: "inject-objects",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("1.31.0", api => {
        // Log out of battle.net after we've destroyed our session.
        api.modifyClass("route:application", {
          pluginId: "blizzard",
          actions: {
            logout() {
              let backTo = encodeURIComponent(document.location.href);
              this.currentUser.destroySession().then(() => {
                window.location.href = `${this.site.bnet_logout_url}?ref=${backTo}`;
              });
            }
          }
        });
        api.modifyClass("service:restricted-routing", {
          pluginId: "blizzard",
          get _needs2fa() {
            // TODO: Once blizzard decide they are ready, we can remove the site setting condition.
            const enforcing2fa = this.currentUser?.staff && siteSettings.enforce_second_factor_on_external_auth;
            const exemptedFrom2faEnforcement = !this.currentUser || this.currentUser.is_anonymous || this.currentUser.second_factor_enabled;
            return enforcing2fa && !exemptedFrom2faEnforcement;
          }
        });
        api.modifyClass("component:modal/avatar-selector", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "fetchedAvatars", [_tracking.tracked]))();
          #fetchedAvatars = (() => (dt7948.i(this, "fetchedAvatars"), void 0))();
          get selectableAvatars() {
            if (this.fetchedAvatars) {
              return this.fetchedAvatars;
            } else if (siteSettings.selectable_avatars_mode !== "disabled") {
              (0, _ajax.ajax)("/site/selectable-avatars.json").then(result => this.fetchedAvatars = result);
            } else {
              return [];
            }
          }
          static #_2 = (() => dt7948.n(this.prototype, "selectableAvatars", [_tracking.cached]))();
        });

        // Client side display of usernames: strip battletag
        api.formatUsername(username => username.replace(/\-.*$/, ""));

        // Show the footer on the error page
        api.modifyClass("route:unknown", {
          pluginId: "blizzard",
          showFooter: true
        });
        if (siteSettings.blizzard_realms_enabled) {
          // Exclude realms from bread crumbs
          api.modifyClass("component:bread-crumbs", {
            pluginId: "blizzard",
            parentCategories: (0, _computed.filter)("categories", function (c) {
              let category = this.get("category");
              return !c.get("parentCategory") && (c === category || !c.get("category_metadata.is_realm"));
            })
          });
        }

        // Always show topic in header when available
        api.modifyClass("component:discourse-topic", {
          pluginId: "blizzard",
          shouldShowTopicInHeader() {
            return true;
          }
        });

        // Always use a down arrow for the category dropdown
        api.modifyClass("component:category-drop", {
          pluginId: "blizzard",
          caretDownIcon: "caret-down"
        });
        api.modifyClass("component:glimmer-site-header", {
          pluginId: "blizzard",
          _docAt: 48
        });
        if (siteSettings.selectable_characters_enabled) {
          api.modifyClass("component:user-card-contents", {
            pluginId: "blizzard",
            classNameBindings: ["selectableCharacter"],
            selectableCharacter: (0, _object.computed)("user.profile_hidden", function () {
              if (!this.get("user.profile_hidden")) {
                return "selectable-characters-enabled";
              }
            })
          });
        }
        api.modifyClass("controller:user", {
          pluginId: "blizzard",
          canCheckEmails: false,
          canExpandProfile: false,
          collapsedInfo: (0, _computed.alias)("model.profile_hidden")
        });
        api.modifyClass("controller:preferences/account", {
          pluginId: "blizzard",
          canCheckEmails: false
        });

        // Disable the user's ability to download their posts
        api.modifyClass("controller:user-activity", {
          pluginId: "blizzard",
          canDownloadPosts: false
        });

        // Don't render the header login/signup buttons
        api.headerButtons.delete("auth");
        if (siteSettings.selectable_characters_enabled) {
          api.addPostTransformCallback(t => {
            let ucf = t.userCustomFields;
            if (ucf) {
              t.character = {
                name: (0, _utilities.formatUsername)(t.username),
                guild: ucf.guild,
                achievement_points: ucf.achievement_points,
                level: ucf.level,
                class: ucf.class,
                race: ucf.race,
                player_class_enum: ucf.player_class_enum,
                classic: ucf.classic === "t" ? (0, _discourseI18n.i18n)("wow.classic") : false
              };
            } else {
              t.character = {
                name: (0, _utilities.formatUsername)(t.username)
              };
            }
          });
        }

        // Customize posts
        api.changeWidgetSetting("poster-name", "hideNotes", true);
        api.changeWidgetSetting("post-avatar", "showNotes", true);
        api.changeWidgetSetting("post-avatar", "size", "huge");
        api.decorateWidget("post-meta-data:after", helper => {
          let attrs = helper.attrs;
          return helper.attach("b-post-statuses", attrs);
        });
        api.includePostAttributes("created_ip_address");
        api.includePostAttributes("battletag");
        api.includePostAttributes("user_post_count");

        // Show character instead of poster name
        if (siteSettings.selectable_characters_enabled) {
          api.reopenWidget("poster-name", {
            html(attrs) {
              return this.attach("character-details", attrs);
            }
          });
        }

        // Show post count in topic
        api.decorateWidget("poster-name:after-name", helper => {
          return helper.attach("user-post-count", helper.attrs);
        });

        // On blizzard forums, only Blizzard Employees can edit their names
        api.modifyClass("controller:preferences/account", {
          pluginId: "blizzard",
          canEditName: (0, _computed.alias)("model.blizzard_employee"),
          canSaveUser: (0, _computed.alias)("model.blizzard_employee")
        });

        // blizzard doesn't want to show the group popup. We do this by overriding
        // the code that wires up the card but not calling super.
        api.modifyClass("component:group-card-contents", {
          pluginId: "blizzard",
          _show() {}
        });
        if (siteSettings.blizzard_enable_dsa_prompt) {
          api.modifyClass("component:modal/flag", Superclass => class extends Superclass {
            get flagsAvailable() {
              let dsaFlag = {
                id: 1004,
                description: (0, _discourseI18n.i18n)("blizzard.report_content.description"),
                is_flag: true,
                is_custom_flag: false,
                is_dsa: true,
                name: (0, _discourseI18n.i18n)("blizzard.report_content.title"),
                name_key: "dsa_report"
              };
              return this.args.model.flagTarget.flagsAvailable(this).concat([dsaFlag]);
            }
            createFlag() {
              let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
              if (this.selected.is_dsa) {
                let url = (0, _getUrl.getAbsoluteURL)(`/dsa/report.json?post_id=${this.args.model.flagModel.id}`);
                window.open(url, "_blank", "noopener=true");
                this.args.closeModal();
              }
              if (this.selected.is_custom_flag) {
                opts.message = this.message;
              }
              this.args.model.flagTarget.create(this, opts);
            }
            static #_ = (() => dt7948.n(this.prototype, "createFlag", [_object.action]))();
          });
        }
      });
    }
  };
});