define("discourse/plugins/discourse-blizzard-plugin/api-initializers/customize-post-menu", ["exports", "discourse/lib/api", "discourse/plugins/discourse-blizzard-plugin/discourse/components/assign-anon-report-button"], function (_exports, _api, _assignAnonReportButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.34", api => {
    api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag
      } = _ref;
      dag.add("assign-custom", _assignAnonReportButton.default, {
        before: "delete",
        after: "assign"
      });
    });
    api.registerValueTransformer("post-menu-buttons", _ref2 => {
      let {
        context
      } = _ref2;
      context.buttonLabels.show(context.buttonKeys.REPLY);
    });
    api.registerValueTransformer("post-menu-collapsed", () => false);
  });
});