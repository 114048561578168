define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/before-group-container/group-navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.admin}}
    {{#link-to
      route="group.manage"
      model=this.group.name
      class="btn-primary btn btn-icon-text blizzard-manage-group"
    }}
      {{d-icon "wrench"}}
      Manage Group
    {{/link-to}}
  {{/if}}
  
  <div class="list-controls">
    <section class="navigation-container">
      {{d-navigation
        filterMode=(concat "group-" this.group.name)
        category=this.category
      }}
    </section>
  </div>
  */
  {
    "id": "zCqJPVXG",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[6,[39,1],null,[[\"route\",\"model\",\"class\"],[\"group.manage\",[30,0,[\"group\",\"name\"]],\"btn-primary btn btn-icon-text blizzard-manage-group\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],[\"wrench\"],null]],[1,\"\\n    Manage Group\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"list-controls\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"navigation-container\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"filterMode\",\"category\"],[[28,[37,6],[\"group-\",[30,0,[\"group\",\"name\"]]],null],[30,0,[\"category\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"link-to\",\"d-icon\",\"div\",\"section\",\"d-navigation\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/before-group-container/group-navigation.hbs",
    "isStrictMode": false
  });
});