define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-small", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href={{this.href}} class="b-category-small d-link {{this.hasDescription}}">
    <img alt="" src={{b-category-icon @category}} />
    <span class="b-category-label">{{@category.name}}</span>
  </a>
  */
  {
    "id": "6PzWwg0E",
    "block": "[[[10,3],[15,6,[30,0,[\"href\"]]],[15,0,[29,[\"b-category-small d-link \",[30,0,[\"hasDescription\"]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"alt\",\"\"],[15,\"src\",[28,[37,2],[[30,1]],null]],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"b-category-label\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[13]],[\"@category\"],false,[\"a\",\"img\",\"b-category-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-category-small.hbs",
    "isStrictMode": false
  });
  class BCategorySmall extends _component2.default {
    get hasDescription() {
      return this.args.category.description_excerpt && this.args.category.description_excerpt.length ? "has-description" : "no-description";
    }
    get href() {
      if (this.args.category) {
        return this.args.category.url;
      }
      return null;
    }
  }
  _exports.default = BCategorySmall;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BCategorySmall);
});